body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.list-spaced li {
    margin: 1em 0;
}

.content-panel {
    padding: 1em;
    margin: 5em 1.2em 1em;
    border: 1px solid darkgray;
    border-radius: 5px;
}


.page-title-section {
    margin: 15px 2em;
}

.page-title-section h1 {
    font-size: 2.25em;
    color: #565454;
    margin-top: 10px;
}

.footer-btn-container {
    height: 80px;
    margin: 0 1.8em;
}

.section-btn-container {
    min-width: 200px;
}

.section-large-title {
    font-size: 2em;
}

.section-title {
    font-size: 1.6em;
}

.signature-canvas-container {
    position: relative;
}

.signature-canvas {
    border: 1px solid #ccc;
    border-radius: 5px;
    min-width: 98%;
    max-height: 150px;
}

.clear-canva-btn {
    position: absolute;
    top: 0;
    right: 2%;
}

.datatable {
    height: 100%;
}

.datatable .rdt_TableRow {
    padding: 15px 0;
}

.datatable .rdt_TableCell {
    padding-left: 15px;
    padding-right: 15px;
}

.text-justify {
    text-align: justify;
    text-justify: inter-word;
}

.no-margin-paragraph p {
    margin: 0;
}

.text-lg {
    font-size: 1.25em;
}

.text-xl {
    font-size: 2.5em;
}

.main-content-container {
    margin-bottom: 100px;
}

.soft-deleted-row {
    color: lightgrey;
}
